<template>
    <work-team-module-component/>
</template>

<script>
    import WorkTeamModuleComponent from "@/components/admin/WorkTeamModuleComponent";

    export default {
        name: "WorkTeamModule",
        title: "Equipo de Trabajo | Private",
        components: { WorkTeamModuleComponent }
    }
</script>

<style scoped>

</style>